.email-error-svg-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  max-width: 270px;
  width: 100%;
  padding: 50px;
}

.email-error-svg-circle {
  position: absolute;
}

.email-error-svg-illustration {
  width: 100%;
  z-index: 1;
}

.email-error-svg-border {
  position: absolute;
  border-radius: 14px;
  width: 110%;
  height: 110%;
}

@media only screen and (max-width: 400px) {
  .email-error-svg-container {
    padding: 70px;
    max-width: 250px;
  }
}