.service-card-card {
  display: flex;
  flex-direction: column;
  max-width: 230px;
  width: 100%;
  height: auto;
  flex: 1 1 auto;
  cursor: pointer;
}

.service-card-img-container {
  width: auto;
  height: auto;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 150px;
}

.service-card-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* border-radius: 50%; */
}

.service-card-drawer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  width: 100%;
  height: 100%;
}

.service-card-drawer-img-container {
  width: 200px;
  height: 200px;
  display: flex;
  align-self: center;
}

.service-card-drawer-column {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
}