.email-svg-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  max-width: 270px;
  width: 100%;
  padding: 80px;
}

.email-svg-circle {
  position: absolute;
  border-radius: 50%;
}

.email-svg-illustration-container {
  display: flex;
  align-items: end;
  justify-content: center;
  position: relative;
}

.email-svg-illustration-bg {
  background: #FFFFFF;
  width: 100%;
  height: 60%;
  display: flex;
  position: absolute;
  border-radius: 2px;
}

.email-svg-illustration {
  width: 100%;
  z-index: 1;
}

.email-svg-border {
  position: absolute;
  border-radius: 50%;
  width: 110%;
  height: 110%;
}

@media only screen and (max-width: 400px) {
  .email-svg-container {
    padding: 70px;
    max-width: 250px;
  }
}