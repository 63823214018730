.hero-container {
  margin-top: 24px;
}

.hero-button {
  display: flex;
  margin-top: 24px !important;
}

.hero-left-column {
  padding-top: 48px !important;
  min-height: 300px;
}

.hero-logo-size {
  width: 500px;
  margin-bottom: 48px
}

.hero-logo-urqu {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 900px) {
  .hero-left-column {
    padding-top: 64px !important;
  }

  .hero-logo-size {
    width: 350px;
  }

  .hero-button {
    align-self: center;
  }
}

@media only screen and (min-width: 901px) {
  .hero-left-column {
    padding-right: 24px !important;
  }
}

@media only screen and (max-width: 600px) {
 .hero-logo-size {
    width: 290px;
  }
}

@media only screen and (max-width: 400px) {
  .hero-logo-size {
    width: 260px;
 }

  .hero-logo-light-circle-size {
    width: 234px;
  }

}