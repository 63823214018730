body {
  margin-top: 80px !important;
  box-sizing: border-box;
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
}

.urq-rounded {
  border-radius: 24px !important;
}

/* ---------------------------------------------
    URQ-FLEXBOX HELPERS
--------------------------------------------- */

.urq-flex {
  display: flex;
}

.urq-flex-row {
  flex-direction: row;
}

.urq-flex-column {
  flex-direction: column !important;
}


.urq-justify-center {
  justify-content: center !important;
}

.urq-justify-space-between {
  justify-content: space-between !important;
}

.urq-justify-end {
  justify-content: flex-end !important;
}

.urq-justify-start {
  justify-content: flex-start !important;
}

.urq-justify-self-center {
  justify-self: center !important;
}

.urq-justify-self-space-between {
  justify-self: space-between !important;
}

.urq-justify-self-end {
  justify-self: flex-end !important;
}

.urq-justify-self-start {
  justify-self: flex-start !important;
}

.urq-align-end {
  align-items: flex-end !important;
}

.urq-align-start {
  align-items: flex-start !important;
}

.urq-align-center {
  align-items: center !important;
}

.urq-align-self-end {
  align-self: flex-end !important;
}

.urq-align-self-start {
  align-self: flex-start !important;
}

.urq-align-self-center {
  align-self: center !important;
}

.urq-flex-is-wrap {
  flex-wrap: wrap;
}



/* ---------------------------------------------
    MARGINS & PADDINGS
--------------------------------------------- */

.urq-mx-8 {
  margin: 0 8px !important;
}

.urq-mb-64 {
  margin-bottom: 64px !important;
}

.urq-px-8 {
  padding: 0 8px !important;
}

.urq-my-24 {
  margin: 24px 0 !important;
}

.urq-my-16 {
  margin: 16px 0 !important;
}

.urq-mt-8 {
  margin-top: 2px !important;
}

.urq-mt-12 {
  margin-top: 12px !important;
}

.urq-ml-12 {
  margin-left: 12px;
}

.urq-ml-8 {
  margin-left: 8px;
}

.urq-mb-12 {
  margin-bottom: 12px !important;
}
.urq-n-ml-10 {
  margin-left: -10px !important;
}

.urq-mr-16 {
  margin-right: 16px !important;
}

.urq-ml-16 {
  margin-left: 16px !important;
}

.urq-mr-24 {
  margin-right: 24px !important;
}

.urq-ml-24 {
  margin-left: 24px !important;
}

.urq-mt-16 {
  margin-top: 16px !important;
}

.urq-mt-18 {
  margin-top: 18px !important;
}

.urq-mt-32 {
  margin-top: 32px !important;
}

.urq-mt-64 {
  margin-top: 64px !important;
}

.urq-mt-48 {
  margin-top: 48px !important;
}

.urq-my-64 {
  margin: 64px 0 !important;
}

.urq-mt-24 {
  margin-top: 24px !important;
}

.urq-pr-16 {
  padding-right: 16px;
}

.urq-pt-32 {
  padding-top: 32px !important;
}

.urq-pt-24 {
  padding-top: 24px !important;
}

.urq-pt-64 {
  padding-top: 64px !important;
}


.urq-width-complete {
  width: 100%;
}

.urq-max-width-complete {
  max-width: 100%;
}

.urq-height-complete {
  height: 100%;
}

/* ---------------------------------------------
    TEXT HELPERS
--------------------------------------------- */

.urq-text-align-center {
  text-align: center !important;
}


/* ---------------------------------------------
    BORDER HELPERS
--------------------------------------------- */

.border-blue {
  border: 2px solid blue;
}

.border-red {
  border: 2px solid red;
}

.border-green {
  border: 2px solid green;
}

.border-purple {
  border: 2px solid purple;
}

.border-pink {
  border: 2px solid pink;
}

.border-yellow {
  border: 2px solid yellow;
}

.border-black {
  border: 2px solid black;
}

.urq-button-size {
  max-width: 300px;
  min-width: 260px;
  width: 100%;  
}