.services-container {
  margin-top: 24px;
}

.services-button {
  display: flex;
  align-self: start;
  margin-top: 24px !important;
}

.services-left-column {
  padding-top: 48px !important;
  min-height: 300px;
}

.services-logo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: black;
}

.services-logo-size {
  width: 400px;
  height: 400px;
}

.services-logo-light-circle-size {
  width: 414px;
  height: 414px;
}

.services-logo-urqu {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  padding: 0 20px;
  z-index: 1;
}

.services-logo-texture {
  border-radius: 50%;
  object-fit: cover;
  opacity: 0.8;
}

.services-logo-circle {
  position: absolute;
  border-radius: 50%;
  left: 1%;
  top: 1.6%;
  z-index: -1;
}

.services-logo-dark-circle {
  width: 412px;
  height: 412px;
  position: absolute;
  border-radius: 50%;
  opacity: 0.2;
  left: -12%;
  top: 1%;
}

@media only screen and (max-width: 900px) {
  .services-container {
    margin-top: 16px;
  }

  .services-left-column {
    padding-top: 64px !important;
  }

  .services-logo-size {
    width: 350px;
    height: 350px;
  }

  .services-logo-light-circle-size {
    width: 364px;
    height: 364px;
  }

  .services-logo-dark-circle {
    width: 362px;
    height: 362px;
  }

  .services-button {
    align-self: center;
  }
}

@media only screen and (min-width: 901px) {
  .services-left-column {
    padding-right: 24px !important;
  }
}

@media only screen and (max-width: 600px) {
  .services-container {
    margin-top: 12px;
  }
    
  .services-logo-size {
    width: 290px;
    height: 290px;
  }

  .services-logo-light-circle-size {
    width: 304px;
    height: 304px;
  }

  .services-logo-dark-circle {
    width: 302px;
    height: 302px;
  }
}

@media only screen and (max-width: 400px) {
  .services-container {
    margin-top: 10px;
  }    
    
  .services-logo-size {
    width: 220px;
    height: 220px;
  }

  .services-logo-light-circle-size {
    width: 234px;
    height: 234px;
  }

  .services-logo-dark-circle {
    width: 232px;
    height: 232px;
  }
}