.avatar-card {
  display: flex;
  flex-direction: column;
  max-width: 230px;
  width: 100%;
  height: auto;
  flex: 1 1 auto;
  cursor: pointer;
}

.avatar-img-container {
  width: auto;
  height: auto;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.avatar-drawer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  width: 100%;
  height: 100%;
}

.avatar-drawer-img-container {
  width: 200px;
  height: 200px;
  display: flex;
  align-self: center;
}

.avatar-drawer-column {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
}