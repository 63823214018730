.bullet-card {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  width: 100%;
  cursor: pointer;
  border-radius: 25px !important;
}

.bullet-card-img-container {
  width: 100%;
  height: 120px;
  border-radius: 16px;
  padding:16px 0px;
}

.bullet-card-img {
  width: 100%;
  height: 100%;
}