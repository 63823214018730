.contact-y-spacing {
  margin-top: 32px !important;
  margin-bottom: 24px !important;
}

.contact-email-svg-container {
  max-width: 320px;
  width: 100%;
  height: 210px;
  position: relative;
  display: flex;
  align-items: end;
  margin-top: 48px;
  border-radius: 25px;
}

.contact-email-svg {
  width: 100%;
  position: absolute;
}

.contact-email-bg {
  width: 100%;
  position: absolute;
  height: 100%;
  border-radius: 25px;
}

.contact-email-border {
  width: 100%;
  position: absolute;
  height: 100%;
  border-radius: 25px;
  bottom: 4%;
  left: 4%;
}

.contact-email-rectangle {
  width: 100%;
  position: absolute;
  height: 100%;
  border-radius: 25px;
  top: 4%;
  left: 6.2%;
  opacity: 0.4;
}

.contact-button {
  display: flex;
  justify-self: center;
  width: 100%;
  margin-top: 24px !important;
}

.contact-urqu-email {
  margin-top: 24px !important;
}

@media only screen and (max-width: 400px) {
  .contact-email-svg-container {
    max-width: 240px;
    height: 148px;
  }
}